import React from "react";
import Content from "../components/Content";
import Hero from "../components/Hero";

export default function Home() {
  return (
    <div>
      <Hero />
      <Content />
    </div>
  );
}
